<script>
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Tambah Posisi Jabatan",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
    },
    data() {
        return {
            title: "Tambah Posisi Jabatan",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "Posisi Jabatan",
                    href: "/master/Posisi Jabatan",
                },
                {
                    text: "Tambah Posisi Jabatan",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,
            // variable Page
            role_selected: '',

            file_ttd: "",
            master_posisi: [],
            posisi: "",
            posisi_jabatan: "",
            nama_singkatan: "",
            posisi_selected: ""
        };
    },
    mounted() {
        this.getPosisi();
    },
    methods: {
        getPosisi() {
            let self = this;
            self.loadingTable = true;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/posisi?status=ENABLE")
                .then((response) => {
                    var response_data = response.data;
                    if (response_data.code == 200) {
                        self.master_posisi = response_data.list_data.data;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.message,
                        });
                    }
                });
        },
        uploadFile() {
            let self = this;
            if ($("#upload")[0].files[0]) {
                if ($("#upload")[0].files[0].size < 5000000) {
                    $("#uploadLoading").html(
                        '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
                    );
                    var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#upload")[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "api/master/uploadhandle/uploadsttd",
                        headers: {
                            Accept: "application/json",
                        },
                        data: data,
                    };
                    axios(config).then(function (response) {
                        var dir_name = response.data.dir_name;
                        var file_name = response.data.file_name;
                        urlres += dir_name;
                        $("#uploadLoading").html(
                            '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                            urlres +
                            '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
                        );
                        self.file_ttd = file_name;
                    }).catch((e) => {
                        Swal.fire({
                            icon: "error",
                            title: "Gagal upload file",
                            text: e.response?.data?.message?.file,
                        });
                    });
                }
            }
        },
        StoreData() {
            let self = this;
            var posisi_id = self.posisi_selected?.mp_id;
            if (posisi_id) {
                posisi_id = self.posisi_selected?.mp_id;
            } else {
                posisi_id = '';
            }
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var FormData = require("form-data");
            var data = new FormData();
            data.append("posisi_id", posisi_id);
            data.append("posisi_jabatan", self.posisi_jabatan);
            data.append("nama_singkatan", self.nama_singkatan);
            data.append("file_ttd", self.file_ttd);

            var config = {
                method: "post",
                url:
                    process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/posisijabatan/store",
                data: data,
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    if (response_data.code != 200) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            html: response_data.message,
                        });
                    } else {
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman master posisi jabatan segera",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.$emit("refresh-table", "add");
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <b-form class="p-2" @submit.prevent="StoreData">
                <div class="row">
                    <div class="col-md-12">
                        <b-form-group class="mb-3" label="Posisi">
                            <v-select placeholder="-Pilih Posisi-" :options="master_posisi" label="mp_nama"
                                v-model="posisi_selected"></v-select>
                        </b-form-group>
                        <b-form-group class="mb-3" label="Nama Jabatan">
                            <b-form-input type="text" v-model="posisi_jabatan"></b-form-input>
                        </b-form-group>
                        <b-form-group class="mb-3" label="Nama Singkatan">
                            <b-form-input type="text" v-model="nama_singkatan"></b-form-input>
                        </b-form-group>
                        <b-form-group class="mb-3" label="File Tanda Tangan" accept="image/jpeg, image/png">
                            <input type="file" id="upload" @change="uploadFile">
                        </b-form-group>
                        <input type="hidden" v-model="file_ttd">
                        <div class="respond-input-file float-left" id="uploadLoading"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-end">
                        <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i>
                            Simpan</b-button>
                    </div>
                </div>
            </b-form>
        </div>
    </div>
</template>
